var AjgAccordion = {
    configs: {
        item: ".ajg-accordion-item",
        expanded: "ajg-accordion-expanded",
        collapsed: "ajg-accordion-collapsed",
        focus: "ajg-tab-focus",
        unFocus: "ajg-tab-unfocus",
        icons: "ajg-accordion-plus ajg-accordion-minus",
        expandedIcons: "ajg-accordion-minus ajg-accordion-plus",
        hashValue: window.location.hash.substr(1),
    },
    init: function () {
        this.bindUIActions(this.configs), 
        this.addSpanForBulletListRTE(this.configs);
      //   this.setDefaultState(this.configs);
    },
    bindUIActions: function (o) {
        !1 === o.disableJS &&
            ($(o.heading).on("mousedown focus blur", function (t) {
                t.stopPropagation(), t.preventDefault();
                var e = 0 < $(this).closest("form").length,
                    a = $(this).parent(o.item);
                if ((e && a.hasClass(o.collapsed)) || "mousedown" == t.type || !e) {
                    var i = $(this).attr("aria-expanded");
                    i = "true" === i ? "false" : "true";
                    var n = "true" === (n = $(this).parent(o.item).find(".ajg-accordion-section").attr("aria-hidden")) ? "false" : "true";
                    $(this).attr("aria-expanded", i),
                        $(this).parent(o.item).toggleClass(o.expanded).toggleClass(o.collapsed),
                        $(this).parent(o.item).find(".ajg-accordion-section").attr("aria-hidden", n),
                        $("span:first", this).toggleClass(o.icons);
                }
            }),
            $(o.heading).on("focus", function () {
                $(this).addClass(o.focus);
            }),
            $(o.heading).on("blur", function () {
                $(this).removeClass(o.focus);
            }));
    },
    addSpanForBulletListRTE: function () {
        $(".ajg-accordion-bulletlist").find("li").find("span:first").contents().unwrap(), $(".ajg-accordion-bulletlist").find("li").wrapInner("<span></span>");
    },
    setDefaultState: function (n) {
        n.accordionContainers.each(function () {
            var a = $(this).data("start-expanded");
            if ("closeall" === a)
                $(this)
                    .find(n.item)
                    .each(function (t) {
                        if (0 <= t && !1 === n.disableJS) {
                            var a = $(this).find(".ajg-accordion-heading").attr("aria-expanded") ? "false" : "true",
                                i = $(this).find(".ajg-accordion-section").attr("aria-hidden") ? "true" : "false";
                            $(this).removeClass(n.expanded).addClass(n.collapsed),
                                $(this).find(".ajg-accordion-heading").attr("aria-expanded", a),
                                $(this).find(".ajg-accordion-section").attr("aria-hidden", i),
                                $("span:first", this).toggleClass(n.icons);
                        }
                        !0 === n.disableJS && $(this).removeClass(n.collapsed).addClass(n.expanded);
                    });
            else if ("expandall" === a) {
                $(this)
                    .find(n.item)
                    .each(function (t) {
                        if (0 <= t && !1 === n.disableJS) {
                            var a = $(this).find(".ajg-accordion-heading").attr("aria-expanded") ? "true" : "false",
                                i = $(this).find(".ajg-accordion-section").attr("aria-hidden") ? "false" : "true";
                            $(this).removeClass(n.collapsed).addClass(n.expanded),
                                $(this).find(".ajg-accordion-heading").attr("aria-expanded", a),
                                $(this).find(".ajg-accordion-section").attr("aria-hidden", i),
                                $(this).find(".ajg-accordion-heading").find("span:first").addClass("ajg-accordion-minus");
                        }
                        !0 === n.disableJS && $(this).removeClass(n.collapsed).addClass(n.expanded);
                    });
            } else {
                $(this)
                    .find(n.item)
                    .each(function (t) {
                        if (0 < t && !1 === n.disableJS) {
                            var a = $(this).find(".ajg-accordion-heading").attr("aria-expanded") ? "false" : "true",
                                i = $(this).find(".ajg-accordion-section").attr("aria-hidden") ? "true" : "false";
                            $(this).removeClass(n.expanded).addClass(n.collapsed),
                                $(this).find(".ajg-accordion-heading").attr("aria-expanded", a),
                                $(this).find(".ajg-accordion-section").attr("aria-hidden", i),
                                $("span:first", this).toggleClass(n.icons);
                        }
                        !0 === n.disableJS && $(this).removeClass(n.collapsed).addClass(n.expanded);
                    });
            }
        });
    }, 
};
$(document).ready(function () {
        AjgAccordion.configs= {
            item: ".ajg-accordion-item",
            heading: $(".ajg-accordion-heading"),
            expanded: "ajg-accordion-expanded",
            collapsed: "ajg-accordion-collapsed",
            focus: "ajg-tab-focus",
            unFocus: "ajg-tab-unfocus",
            icons: "ajg-accordion-plus ajg-accordion-minus",
            expandedIcons: "ajg-accordion-minus ajg-accordion-plus",
            accordionContainers: $(".ajg-accordion"),
            disableJS: $(".ajg-accordion").data("edit-status"),
            hashValue: window.location.hash.substr(1),
        };
        AjgAccordion.init()
  
});
