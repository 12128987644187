export default function Navigation () {
	toggleNavMenu()
}

function toggleNavMenu() {
	
	const navbar = document.getElementsByClassName('navbar')[0];

	window.onscroll = function() {onScroll()};

	function onScroll() {
		if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
			navbar.classList.add('navbar-scroll');
		} else {
			navbar.classList.remove('navbar-scroll');
		} 
	}

}

//Burger menu animation
$(document).ready(function(){
	$('.mobile-nav-icon').click(function(){
		$(this).toggleClass('open');
		$('.navbar-links').toggleClass('mobile-toggle');
		$('.navbar').toggleClass('full-white');
		$('body').toggleClass('overflow-hidden');
	});
});


$(window).scroll(function(){
    if ($(this).scrollTop() > 10) {
       $('.header').addClass('header-dropshadow');
    } else {
       $('.header').removeClass('header-dropshadow');
    }
});